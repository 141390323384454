.player-progress .mb-2{
  cursor: pointer;
  background: #000000;
}
.player-controls {
  display: flex;
  justify-content: space-between;
  background: #000000;
}
.player-volume-control {
  width: 15%;
  min-width: 150px;
  font-size: small;
  justify-content: space-around;
  display: flex;
}
.player-volume-control .range-select {
  width: 60%;
}

.player-volume-control .input-range__slider {
  height: 0.5rem;
  width: 0.5rem;
  margin-left: -0.30rem;
  margin-top: -0.40rem;
  top: 30%;
}
.player-volume-control .input-range__label--value {
}
.player-volume-control .input-range__label--max {
}
.player-volume-control .input-range__label--min {
}
.player-volume-control .input-range__slider {
  background: rgb(130, 0, 255);
  border: 1px solid #000000;
}

.player-volume-control .input-range__track--active {
  background: #000000;
}

.bars-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 50%;
  transform:translate(0%, -50%);

}

.frequency-bars {
  display: none;
}

html{
  width: 100%;
  height: 100%;
}

/*start z index shit*/
.sinewave{
  z-index:0;
}
.message-top {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 98%;
  color: white;
  font-family: Menlo;
  font-size: 12px;
} 
/*end z index shit*/

.message-middle {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 87%;
  color: white;
  font-family: Menlo;
  font-size: 12px;
} 
.message-bottom {
  text-align: center;
  position: absolute;
  bottom: 0;
  height: 20%;
  width: 100%;
  color: white;
  font-family: Menlo;
  font-size: 12px;

}  
.message-bottomer {
  text-align: center;
  position: absolute;
  bottom: 0;
  height: 6%;
  width: 100%;
  color: white;
  font-family: Menlo;
  font-size: 12px;
} 
.dead{
  visibility: hidden;
  display: none;
}
.playBtn {
  top: 50%;
  left: 50%;
  
  color:grey;
}
.loading {
  filter: blur(1.5rem);
}

img {
  width: 400px;
  height:400px;
  object-fit: contain;
  
}

.imgWrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 2;
  transform:translate(0%, -50%);

}

.dead{
  visibility: hidden;
  display: none;
}

.player {
  height: 100%;
  display: flex;
  align-items: center;
}

.loadWheel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-30%, -30%);
  z-index:4;
  color:grey;
}

.email{
  width:200px;
  text-align: center;
}

input, textarea{
    
    color: #ffffff;
    caret-color: #9494b8;
    background: #9494b8;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-color: white;
    font-family: Menlo;
}

textarea:focus, input:focus {
    color: #ffffff;
    background: #9494b8;
    outline: none;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-color: white;
}
